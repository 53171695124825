<template>
  <form name="sentMessage" novalidate="novalidate" @submit.prevent="submitForm">
    <div class="control-group">
      <div class="form-group floating-label-form-group controls mb-0 pb-2">
        <label>Name</label>
        <input
          class="form-control"
          id="name"
          type="text"
          placeholder="Name"
          required="required"
          data-validation-required-message="Please enter your name."
          v-model="name"
        />
        <p class="help-block text-danger"></p>
      </div>
    </div>
    <div class="control-group">
      <div class="form-group floating-label-form-group controls mb-0 pb-2">
        <label>Email Address</label>
        <input
          class="form-control"
          id="email"
          type="email"
          placeholder="Email Address"
          required="required"
          data-validation-required-message="Please enter your email address."
          v-model="email"
        />
        <p class="help-block text-danger"></p>
      </div>
    </div>
    <div class="control-group">
      <div class="form-group floating-label-form-group controls mb-0 pb-2">
        <label>Phone Number</label>
        <input
          class="form-control"
          id="phone"
          type="tel"
          placeholder="Phone Number"
          required="required"
          data-validation-required-message="Please enter your phone number."
          v-model="number"
        />
        <p class="help-block text-danger"></p>
      </div>
    </div>
    <div class="control-group">
      <div class="form-group floating-label-form-group controls mb-0 pb-2">
        <label>Message</label>
        <textarea
          class="form-control"
          id="message"
          rows="5"
          placeholder="Message"
          required="required"
          data-validation-required-message="Please enter a message."
          v-model="text"
        ></textarea>
        <p class="help-block text-danger"></p>
      </div>
    </div>
    <br />
    <div id="success"></div>
    <div class="form-group">
      <button
        class="btn btn-primary btn-xl"
        id="sendMessageButton"
        type="submit"
      >
        Send
      </button>
    </div>
  </form>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      number: '',
      email: '',
      text: '',
    };
  },
  methods: {
    submitForm() {
      console.log('Nimi: ' + this.name);

      fetch('/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: this.name,
          number: this.number,
          email: this.email,
          text: this.text,
        }),
      })
    }
  },
};
</script>

<style>

</style>
